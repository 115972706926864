import { Shift } from "@src/lib/interface";
import SendBird from "sendbird";

export const ActionType = {
  SET_CHANNELS: "SET_CHANNELS",
  UPDATE_CHANNEL: "UPDATE_CHANNEL",
  SET_UPCOMING_SHIFTS: "SET_UPCOMING_SHIFTS",
};

export interface ChatChannel extends SendBird.GroupChannel {
  shift: Shift;
  metadata: {
    facilityName?: string;
    hcpName?: string;
    lastBooked?: string;
    placementCandidateAccessId?: string;
    placementId?: string;
    placementTitle?: string;
  };
}

export type ChatChannelCustomTypesFilter = "flex" | "placements" | "all";

export interface ChatStore {
  channels: ChatChannel[];
  upcomingShifts: { [key: string]: Shift };
}
